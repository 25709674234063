import React, { useRef, useEffect, useState } from 'react';
import options from './options';
import { ControlledEditor } from '@monaco-editor/react';
import { FillSpinner as Loader } from 'react-spinners-kit';
import './BespokeIde.css';
import { useDispatch, useSelector } from 'react-redux';
import { bespokeActions } from '../../../store/bespokeProperty-slice';
export default function BespokeIdeEditorMobile(props) {

	const valueGetter = useRef();
	const {
		pannelIsOpen
	} = useSelector((state) => state.bespoke);
	const [isEditorReady, setIsEditorReady] = useState(true);

	function handleEditorDidMount(event) {
		// setIsEditorReady(true);
		valueGetter.current = event;
	}
	function restartEditor(value) {
		setIsEditorReady(false);
		setTimeout(() => setIsEditorReady(true), 50);
	}
	useEffect(() => {
		if (!isEditorReady && pannelIsOpen) {
			restartEditor();
		}
	}, [pannelIsOpen, isEditorReady]);

	return (
		<>
			{isEditorReady && 
                <ControlledEditor
                	theme={props.theme}
                	language={props.language}
                	loading={<Loader />}
                	value={props.value && props.value}
                	onChange={props.changeValue}
                	editorDidMount={handleEditorDidMount}
                	options={options}

                />
			}
		</>
	);
}
