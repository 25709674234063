import React, { useEffect, useRef, useState } from 'react';
import options from './options';
import { ControlledEditor } from '@monaco-editor/react';
import { FillSpinner as Loader } from 'react-spinners-kit';
import { FaDesktop } from 'react-icons/fa';
export default function AccountIdeEditorDesktop(props) {

	const [isEditorReady, setIsEditorReady] = useState(false);
	const valueGetter = useRef();
	let resizeTimeout;

	function restartEditor(value) {
		setIsEditorReady(false);
		setTimeout(() => setIsEditorReady(true), 50);
	}

	function windowResize() {
		clearTimeout(resizeTimeout);
		resizeTimeout = setTimeout(restartEditor, 200);
	}

	useEffect(() => {
		restartEditor(props.isOpen);
	}, [props.isOpen]);

	useEffect(() => {
		window.addEventListener('resize', windowResize);
		return function () {
			window.removeEventListener('resize', windowResize);
		};
	}, []);

	function handleEditorDidMount(event) {
		setIsEditorReady(true);
		valueGetter.current = event;
	}

	return (
		<>
			{isEditorReady &&
                <ControlledEditor
                	theme={props.theme}
                	language={props.language}
                	loading={<Loader />}
                	value={props.value && props.value}
                	onChange={props.changeValue}
                	editorDidMount={handleEditorDidMount}
                	options={options}
                	automaticLayout={true}
                />
			}
		</>
	);
}
