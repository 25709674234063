import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {bespokeActions} from '../../../store/bespokeProperty-slice';

import BespokeIdeEditorDesktop from './BespokeIdeEditorDesktop';
import BespokeIdeEditorMobile from './BespokeIdeEditorMobile';
import BespokeLiquidPropertiesList from './BespokeLiquidPropertiesList';
import BespokeLiquidPropertiesListV2 from './BespokeLiquidPropertiesListV2';
import SelectTemplateBespoke from './SelectTemplateBespoke';
import {FaExpandAlt} from 'react-icons/fa';
import Dock from 'react-dock';
import ReactTooltip from 'react-tooltip';
import {v4 as uuidv4} from 'uuid';
import MonacoDiffEditorCampaign from '../../Campaign/Edition/MonacoDiffEditorCampaign';

const items = {
	minHeight: '60px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '20px',
	color: '#e4e6f2',
	padding: '16px 12px',
	textAlign: 'center',
	cursor: 'pointer'
};
const styles = {
	backgroundColor: '#f1f1f1'
};
const idTool = uuidv4();
export default function BespokeEditionIdeContainer({
	screenEnvironment,
	desktopTemplate,
	mobileTemplate,
	match,
	loading,
	seeDiff,
	valuesEditor
}) {

	const {
		wysiwygValues,
		isLiquid,
		seeConfiguration
	} = useSelector((state) => state.bespoke);

	const theme = 'vs-dark';
	const dispatch = useDispatch();
	const [isEditorReady, setIsEditorReady] = useState(false);
	const [toggleState, setToggleState] = useState(1);
	const [languageUsed, setLanguageUsed] = useState('xslt');
	const toggleConfiguration = () => {
		dispatch(bespokeActions.toggleConfiguration(true));
	};
	let resizeTimeout;

	useEffect(() => {
		if (loading) {
			setIsEditorReady(false);
		} else {
			setIsEditorReady(true);
		}
	}, [loading]);

	function windowResize() {
		clearTimeout(resizeTimeout);
		resizeTimeout = setTimeout(restartEditor, 200);
	}

	function restartEditor(value) {
		setIsEditorReady(false);
		setTimeout(() => setIsEditorReady(true), 50);
	}

	useEffect(() => {
		window.addEventListener('resize', windowResize);
		return function () {
			window.removeEventListener('resize', windowResize);
		};
	}, [seeConfiguration]);

	const seeXSLT = () => {
		setToggleState(1);
		setLanguageUsed('xslt');
	};

	const seeCSS = () => {
		setToggleState(2);
		setLanguageUsed('css');
	};

	const seeJS = () => {
		setToggleState(3);
		setLanguageUsed('javascript');
	};

	const seeAngular = () => {
		setToggleState(4);
		setLanguageUsed('wysiwig');
	};

	const seeConfig = () => {
		setToggleState(5);
		setLanguageUsed('json');
	};

	const templateTypeOptionTemplate = [
		{value: 1, label: 'XSLT', editorStyle: 'xml',},
		{value: 2, label: 'Liquid', editorStyle: 'xml'},
	];
	const templateTypeOptionWysiwyg = [
		{value: 1, label: 'Angular', editorStyle: 'html'},
		{value: 2, label: 'Liquid', editorStyle: 'xml'},
	];

	return (
		<>
			<div className='flex_item_full flex flex_v'>
				<div className="contentOfLanguage flex_item_fix">
					<div className="flex flex_justify_start flex_align_end">
						<div className="countBtn">
							<div className={`onglets ${toggleState === 1 ? 'activeOnglet' : ''} ongletHtml`}
								onClick={seeXSLT}
							>

								<p className='mr_3'>
                                    On-site template
									{screenEnvironment === 'desktop' &&
                                        <small>
                                            ({templateTypeOptionTemplate.find(el => el.value === desktopTemplate.contentTemplateType) && templateTypeOptionTemplate.find(el => el.value === desktopTemplate.contentTemplateType).label})
                                        </small>
									}
									{screenEnvironment === 'mobile' &&
                                        <small>
                                            ({templateTypeOptionTemplate.find(el => el.value === mobileTemplate.contentTemplateType) && templateTypeOptionTemplate.find(el => el.value === mobileTemplate.contentTemplateType).label})
                                        </small>
									}
								</p>
							</div>
							<div
								className={`onglets ${toggleState === 2 ? 'activeOnglet' : ''
								} ongletCss`}
								onClick={seeCSS}
							>
                                CSS
							</div>
							<div
								className={`onglets ${toggleState === 3 ? 'activeOnglet' : ''
								} ongletJs`}
								onClick={seeJS}
							>
                                JS
							</div>

							<div
								className={`onglets angularOnglet ${toggleState === 4 ? 'activeOnglet' : ''
								} ongletWys`}
								onClick={seeAngular}
							>
								<p className='mr_3'>
                                    Wysiwyg template
									{screenEnvironment === 'desktop' &&
                                        <small>
                                            ({templateTypeOptionWysiwyg.find(el => el.value === wysiwygValues.desktopTemplate.templateType) && templateTypeOptionWysiwyg.find(el => el.value === wysiwygValues.desktopTemplate.templateType).label})
                                        </small>
									}
									{screenEnvironment === 'mobile' &&
                                        <small>
                                            ({templateTypeOptionWysiwyg.find(el => el.value === wysiwygValues.mobileTemplate.templateType) && templateTypeOptionWysiwyg.find(el => el.value === wysiwygValues.mobileTemplate.templateType).label})
                                        </small>
									}
								</p>
							</div>
							{isLiquid &&
                                <div className={`onglets ${toggleState === 5 ? 'activeOnglet' : ''
                                } ongletConfig`}
                                onClick={seeConfig}>
                                    Config.JSON
                                </div>
							}
						</div>
					</div>
				</div>
				<div className="ideContainer flex_item_full flex flex_v">
					{!seeDiff && isEditorReady && screenEnvironment === 'desktop' && (
						<>
							{toggleState === 1 && (
								<BespokeIdeEditorDesktop
									title="Template"
									language="xml"
									screenEnvironment="desktop"
									changeValue={(prevValue, e) =>
										dispatch(bespokeActions.handleEditorChangeTemplateDesktop(e))
									}
									value={desktopTemplate && desktopTemplate.contentTemplate}
									theme={theme}
									env={match.params.env}
								/>
							)}
							{toggleState === 2 && (
								<BespokeIdeEditorDesktop
									title="CSS"
									language="css"
									screenEnvironment="desktop"
									changeValue={(prevValue, e) =>
										dispatch(bespokeActions.handleEditorChangeCssDesktop(e))
									}
									value={
										desktopTemplate &&
                                        desktopTemplate.css
									}
									theme={theme}
									env={match.params.env}
								/>
							)}
							{toggleState === 3 && (
								<BespokeIdeEditorDesktop
									title="JAVASCRIPT"
									language="javascript"
									screenEnvironment="desktop"
									changeValue={(prevValue, e) =>
										dispatch(bespokeActions.handleEditorChangeJsDesktop(e))
									}
									value={
										desktopTemplate &&
                                        desktopTemplate.javascript
									}
									theme={theme}
									env={match.params.env}
								/>
							)}
							{toggleState === 4 && (
								<BespokeIdeEditorDesktop
									title="Wysiwyg"
									language="html"
									screenEnvironment="desktop"
									changeValue={(prevValue, e) => dispatch(bespokeActions.handleEditorChangeWysiwygValuesDesktop(e))

									}
									value={
										wysiwygValues &&
                                        wysiwygValues.desktopTemplate
                                        	?.template
									}
									theme={theme}
									env={match.params.env}
								/>
							)}
						</>
					)}
					{!seeDiff && isEditorReady && screenEnvironment === 'mobile' && (
						<>
							{toggleState === 1 && (
								<BespokeIdeEditorMobile
									title="Template"
									language="xml"
									screenEnvironment="mobile" /// gerer btn switch qui balance la propr ici
									changeValue={(prevValue, e) => dispatch(bespokeActions.handleEditorChangeTemplateMobile(e))}
									value={mobileTemplate && mobileTemplate.contentTemplate}
									theme={theme}
									env={match.params.env}
								/>
							)}

							{toggleState === 2 && (
								<BespokeIdeEditorMobile
									title="CSS"
									language="css"
									screenEnvironment="mobile"
									changeValue={(prevValue, e) => dispatch(bespokeActions.handleEditorChangeCssMobile(e))}
									value={mobileTemplate && mobileTemplate.css}
									theme={theme}
									env={match.params.env}
								/>
							)}
							{toggleState === 3 && (
								<BespokeIdeEditorMobile
									title="JAVASCRIPT"
									language="javascript"
									screenEnvironment="mobile"
									changeValue={(prevValue, e) => dispatch(bespokeActions.handleEditorChangeJsMobile(e))}
									value={mobileTemplate && mobileTemplate.javascript}
									theme={theme}
									env={match.params.env}
								/>
							)}

							{toggleState === 4 && (
								<BespokeIdeEditorMobile
									title="Wysiwyg"
									language="html"
									screenEnvironment="mobile"
									changeValue={(prevValue, e) => dispatch(bespokeActions.handleEditorChangeWysiwygValuesMobile(e))}
									value={wysiwygValues && wysiwygValues.mobileTemplate?.template}
									theme={theme}
									env={match.params.env}
								/>
							)}
						</>
					)}
					{isLiquid && isEditorReady && toggleState === 5 && (
						<BespokeLiquidPropertiesListV2 />
					)}
                    {seeDiff && toggleState !== 5 &&
                        <MonacoDiffEditorCampaign
                            theme={'vs-dark'}
                            val={valuesEditor}
                            toggleState={toggleState}
                            language={languageUsed}
                        />
                    }
				</div>
				
			</div>
		</>
	);
}