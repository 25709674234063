import React from 'react';
import AccountIdeEditorDesktop from './AccountIdeEditorDesktop';
import AccountIdeEditorMobile from './AccountIdeEditorMobile';
import { useDispatch, useSelector } from 'react-redux';

export default function IdeContainer({ 
	screenEnvironment,
	toggleState,
	handleEditorChangeTemplateDesktop,
	handleEditorChangeCssDesktop,
	slidTemplatesOfSlideModelSelected, 
	handleEditorChangeCssMobile, 
	handleEditorChangeTemplateMobile, 
	handleEditorChangeJsMobile, 
	handleEditorChangeJsDesktop, 
	handleEditorChangeDisplayConditionMobile,
	handleEditorChangeDisplayConditionDesktop,
	theme, 
	env, 
	slideName, 
	isOpen }) {

	const { isReloading } = useSelector(state => state.campaign);

	let templateType =
    {
    	0: 'XSLT',
    	1: 'XSLT',
    	2: 'LIQUID'
    };
	return (
		<div className='flex_item_full editor_wrapper'>
			{
				screenEnvironment === 'desktop' && !isReloading &&
                <>
                	{toggleState === 1 &&
                        <AccountIdeEditorDesktop
                        	title={slidTemplatesOfSlideModelSelected && templateType[slidTemplatesOfSlideModelSelected[screenEnvironment].contentTemplateType]}
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='xml'
                        	screenEnvironment='desktop'
                        	changeValue={handleEditorChangeTemplateDesktop}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.desktop.contentTemplate}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
                        />
                	}
                	{toggleState === 2 &&
                        <AccountIdeEditorDesktop
                        	title='CSS'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='css'
                        	screenEnvironment='desktop'
                        	changeValue={handleEditorChangeCssDesktop}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.desktop.css}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
                        />
                	}
                	{toggleState === 3 &&
                        <AccountIdeEditorDesktop
                        	title='JAVASCRIPT'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='javascript'
                        	screenEnvironment='desktop'
                        	changeValue={handleEditorChangeJsDesktop}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.desktop.javascript}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}

                        />
                	}
                	{toggleState === 4 && 
                        <AccountIdeEditorDesktop
                        	title='DISPLAY CONDITION'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='javascript'
                        	screenEnvironment='desktop'
                        	changeValue={handleEditorChangeDisplayConditionDesktop}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.desktop.displayConditionJavascript}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
                        />
                	}

                </>}
			{
				screenEnvironment === 'mobile' &&
                <>
                	{toggleState === 1 &&
                        <AccountIdeEditorMobile
                        	title={slidTemplatesOfSlideModelSelected && templateType[slidTemplatesOfSlideModelSelected[screenEnvironment].contentTemplateType]}
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='xml'
                        	screenEnvironment='mobile'
                        	changeValue={handleEditorChangeTemplateMobile}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.mobile.contentTemplate}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
                        />
                	}
                	{toggleState === 2 &&
                        <AccountIdeEditorMobile
                        	title='CSS'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='css'
                        	screenEnvironment='mobile'
                        	changeValue={handleEditorChangeCssMobile}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.mobile.css}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
                        />
                	}
                	{toggleState === 3 &&
                        <AccountIdeEditorMobile
                        	title='JAVASCRIPT'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='javascript'
                        	screenEnvironment='mobile'
                        	changeValue={handleEditorChangeJsMobile}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.mobile.javascript}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
                        />

                	}
                	{toggleState === 4 &&
                        <AccountIdeEditorMobile
                        	title='DISPLAY CONDITION'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='javascript'
                        	screenEnvironment='mobile'
                        	changeValue={handleEditorChangeDisplayConditionMobile}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.mobile.displayConditionJavascript}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
                        />

                	}

                </>}
		</div>
	);
}
