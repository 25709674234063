import React, { useState, useEffect, useRef, useCallback } from 'react';
import './CampaignContainer.css';
import Nav from '../Ui/Nav';
import Sidebar from '../Ui/Sidebar';
import HeaderEdition from '../Ui/HeaderEdition';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../utils/useAuthAxios';
import SpinnerOverlay from '../Ui/SpinnerOverlay';
import SelectorCampaign from './SelectorCampaign';
import SelectCustom from '../Ui/SelectCustom';
import IconsContainer from '../../Components/IconsContainer';
import Btn from '../Ui/Btn';
import Status from '../Ui/Status';
import { StatusAlertService } from 'react-status-alert';
import { FaShareAlt, FaSun, FaMoon, FaSave, FaExternalLinkSquareAlt } from 'react-icons/fa';
import { FiRefreshCw } from 'react-icons/fi';
import { AiFillPicture } from 'react-icons/ai';
import IdeContainer from './Edition/IdeContainer';
import MonacoDiffEditorCampaign from './Edition/MonacoDiffEditorCampaign';
import ImagesAccountContainer from '../ImagesAccount/ImagesAccountContainer';
import Modal from './../Ui/Modal';
import ListErrorsValidation from './ListErrorsValidation';
import useDisplayName from '../../Hooks/useDisplayName';
import Avatar from '../Ui/Avatar';
import useSocket from '../../Hooks/UseSocket';
import changeSlideCampaignHook from './changeSlideCampaignHook';
import Portal from '../../Components/Ui/Portal';
import Notification from '../Ui/Notification';
import uuid from 'react-uuid';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { campaignActions } from '../../store/campaign-reducer';
import { bespokeActions } from '../../store/bespokeProperty-slice';

import { fetchDataAccount } from '../../store/uiActions';
import campaignUtils from './campaignUtils';
import ModalErrors from './Edition/ModalErrors';
import useAlert from '../../Hooks/useAlert';
import CampaignService from '../../Services/CampaignService';
import PanelCampaign from './Edition/PanelCampaign';
import NewDropdown from '../../Components/Ui/NewDropdown';
import SelectTemplate from './SelectTemplate';
import EditCampaignTestMode from './Edition/EditCampaignTestMode ';
import EmptyStateCampaign from './Edition/EmptyStateCampaign';
import PopinConfirm from '../../Components/Ui/PopinConfirm';
import Dropdown from '../../Components/Ui/NewDropdown';

export default function CampaignContainer({ accountId, match }) {

	const dispatch = useDispatch();

	const { isReloading,
		modalIpsIsOpen,
		isEmptyState,
		originalCampaign,
		isSave
	} = useSelector(state => state.campaign);
	const setIsRealoading = (e) => {
		dispatch(campaignActions.isReloading(e));
	};
	const { socketWithEvent, roomInfo, message } = useSocket();
	const {
		seeUploadPicture,
	} = useSelector((state) => state.bespoke);
	const { LinkCampaignToBackOffice,
		getTypeMessage,
		templateType,
		createUrlToShare,
		arrayOfLanguages,
		handleChangesWithCopy,
		transformArrayOfSlideIdentifier,
	} = campaignUtils();

	const socket = useSelector(state => state.socket.socket);
	const { getTheDisplayNameOfUser } = useDisplayName();
	const { isAuthenticated, user } = useAuth0();
	const { authAxios } = useAuthAxios();
	const campaignService = new CampaignService(authAxios, match);
	const { createAlerte } = useAlert();

	const [dataCampaigns, setDataCampaigns] = useState();
	const [idOfCampaignSelected, setIdOfCampaignSelected] = useState();
	const [dataHeaderOfCampaignSelected, setDataHeaderOfCampaignSelected] = useState();

	const [datasSlidesOfCampaignSelected, setdatasSlidesOfCampaignSelected] = useState();
	const [slidTemplatesOfSlideModelSelected, setSlidTemplatesOfSlideModelSelected] = useState();

	const [slideName, setSlideName] = useState();
	const [slideId, setSlideId] = useState();
	const [displayIdentifier, setDisplayIdentifier] = useState();
	const [listofDisplayIdentifier, setListofDisplayIdentifier] = useState();
	const [listOfSlides, setListOfSlide] = useState();
	const [templateFormat, setTemplateFormat] = useState();
	const [screenEnvironment, setScreenEnvironment] = useState('desktop');
	const [theme, setTheme] = useState('vs-dark');
	const [seeDiff, setSeeDiff] = useState(false);
	const [isDifferent, setIsDifferent] = useState(false);
	const [isResponsive, setIsResponsive] = useState(true);
	const [toggleState, setToggleState] = useState(1);
	const [valuesEditor, setValuesEditor] = useState();
	const [modalIsOppen, setModalIsOppen] = useState(false);
	const [listErrors, setListErrors] = useState();
	const [hasBloquantError, setHasBloquantError] = useState();
	const [linkToBo, setLinkToBo] = useState();
	const [modalErrorsIsOppen, setModalErrorsIsOppen] = useState(false);
	const [listOfUsers, setListOfUsers] = useState();
	const [listOfMessage, setListOfMessage] = useState([]);
	const [statusOp, setStatusOp] = useState();
	const [isOpen, setisOpen] = useState(false);
	const [reloadModalIsOpen, setreloadModalIsOpen] = useState(false);
	const [abTestIsOppen, setabTestIsOppen] = useState(false);
	const setAccountWorkingDatas = () => {
		dispatch(fetchDataAccount(authAxios, match.params.env, match.params.accountId));
	};
	const onCloseThePanel = () => {
		dispatch(campaignActions.openClosePanelCampaign(false));
	};
	const openThePanel = () => {
		dispatch(campaignActions.openClosePanelCampaign(true));
	};
	const handleIsSaveCp = (vCompare) => {
		dispatch(campaignActions.handleIsSave(vCompare));
	};

	useEffect(() => {
		if (socket) {
			socketWithEvent(socket, 'roomUsers');
			socketWithEvent(socket, 'message');
		}
	}, []);

	useEffect(() => {
		if (message) {
			setListOfMessage([...listOfMessage, message]);
		}
	}, [message]);

	useEffect(() => {
		if (roomInfo) {
			setListOfUsers(roomInfo);
		}
	}, [roomInfo]);

	const updateListOfMessages = (e) => {
		const newListOfMessage = listOfMessage.filter(msg => msg.id !== e.id);
		setListOfMessage(newListOfMessage);
	};

	const statusOperation = () => {
		if (dataHeaderOfCampaignSelected.isStopped) {
			setStatusOp('Stopped');
		}
		else if (dataHeaderOfCampaignSelected.isTest === 2) {
			setStatusOp('Draft');
		}
		else if (moment(dataHeaderOfCampaignSelected.endDate) < today) {
			setStatusOp('Past');
		}
		else if (moment(dataHeaderOfCampaignSelected.startDate) > today && !dataHeaderOfCampaignSelected.isStopped) {
			setStatusOp('Scheduled');
		}
		else {
			setStatusOp('Ongoing');
		}
	};

	const getUsersRoomInformation = () => {
		const newMessage = {
			displayName: 'BEYABLE',
			time: moment(),
			id: uuid(),
			text: `There are ${listOfUsers.length} people logged in on this page ${listOfUsers.map(u => u.displayName).join(', ')}`,
			verb: 'join',
		};
		setListOfMessage([...listOfMessage, newMessage]);
	};
	const resetTemplates = () => {
		setValuesEditor();
	};
	const handleChangeCampaignOriginalBeforSave = (cp) => {
		dispatch(campaignActions.handleChangeOriginalCampaign(cp));
	};

	useEffect(() => {
		if (match.params.accountId && match.params.env) {
			const timer = setTimeout(() => {
				setAccountWorkingDatas();
			}, 700);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [isAuthenticated]);

	useEffect(() => {
		// setCampaignMode(true)
		dispatch(bespokeActions.handleCampaignMode(true));

		//all campaign and get specific campaign details when params idCp 
		if (match.params.idCampaign && match.params.idCampaign !== 'home') {
			setIsRealoading(true);
			setTemplateFormat();
			setSeeDiff(false);
			socket && socket.emit('leave-room');
			handleChangeCampaignOriginalBeforSave({});
			handleIsSaveCp(true);
			const fetchData = async () => {
				campaignService.getCampaignSelectList(
					(res) => {
						setIsRealoading(false);
						setDataCampaigns(res.data);
						setIdOfCampaignSelected(match.params.idCampaign);
					},
					(error) => {
						setIsRealoading(false);
						createAlerte('error', 'an error occurred', {
							removeAllBeforeShow: false,
						});
					}
				);

				campaignService.getOneCampaignWithParams(
					(res) => {
						socket && socket.emit('joinRoom', getCurrentuser(user, match.params.idCampaign));
						setIsRealoading(false);
						handleChangeCampaignOriginalBeforSave({ ...res.data.slideModels[0] });
						setdatasSlidesOfCampaignSelected(res.data.slideModels);
						dispatch(campaignActions.handleChangeEmptyState(false));
						if (res.data.slideModels[0].slideTemplates.length > 1) {
							let arrayOfSlideIdentifier = transformArrayOfSlideIdentifier(res.data.slideModels[0].slideTemplates);
							setListofDisplayIdentifier(arrayOfSlideIdentifier);
						} else {
							setListofDisplayIdentifier();
						}
						if (res.data.slideModels.length > 1) {
							let arrayOfSlide = res.data.slideModels.map(
								(elem) => ({
									value: elem.slideId,
									label: elem.slideId,
								})
							);
							setListOfSlide(arrayOfSlide);
						} else {
							setListOfSlide();
						}
						setTemplateFormat(
							res.data.slideModels[0].slideTemplates[0][
								screenEnvironment
							].contentTemplateType
						);
						setDisplayIdentifier(
							res.data.slideModels[0].slideTemplates[0]
								.displayIdentifier
						);
						setSlidTemplatesOfSlideModelSelected(
							res.data.slideModels[0].slideTemplates[0]
						);
						setSlideName(res.data.slideModels[0].slideName);
						setSlideId(res.data.slideModels[0].slideId);
					},
					(error) => {
						setIsRealoading(false);
						createAlerte('error', 'an error occurred', {
							removeAllBeforeShow: false,
						});
					}
				);
			};

			if (isAuthenticated) {
				const timer = setTimeout(() => {
					fetchData();
				}, 700);
				return () => {
					clearTimeout(timer);
				};
			}
		}
		//all campaign without specific campaign
		else if (match.params.accountId) {
			handleChangeCampaignOriginalBeforSave({});
			setIsRealoading(true);
			dispatch(campaignActions.handleChangeEmptyState(true));
			const fetchData = async () => campaignService.getCampaignSelectList((res) => {
				setIsRealoading(false);
				openThePanel();
				setDataCampaigns(res.data);
			}, (error) => {
				setIsRealoading(false);
				console.log('error config', error.config);
				createAlerte('error', 'an error occurred', { removeAllBeforeShow: false, });
			});
			if (isAuthenticated) {
				const timer = setTimeout(() => {
					fetchData();
				}, 700);
				return () => {
					clearTimeout(timer);
				};
			}
		}
		return () => { console.log(); };
	}, [isAuthenticated]);

	const reloadCampaignList = () => {
		setIsRealoading(true);
		campaignService.getCampaignSelectList(
			(res) => {
				setIsRealoading(false);
				setDataCampaigns(res.data);
				setIdOfCampaignSelected(idOfCampaignSelected);
			},
			(error) => {
				setIsRealoading(false);
				createAlerte('error', 'an error occurred', {
					removeAllBeforeShow: false,
				});
			}
		);

	};

	const getCurrentuser = (u, roomId) => {
		const room = roomId ? roomId : idOfCampaignSelected;
		const email = u.email;
		const username = u.family_name;
		const firstName = u.family_name;
		const lastName = u.given_name;
		const idBeyable = u.sub;
		const applicationClientSource = 'Studio';
		const displayName = getTheDisplayNameOfUser(u);
		return { displayName, firstName, lastName, email, idBeyable, room, applicationClientSource };
	};
	const getTheInformationsForTheSelectedCampaign = async (idOfCampaign) => {
		resetTemplates();
		socket && socket.emit('leave-room');
		setIsRealoading(true);
		setListOfSlide();
		setTemplateFormat();
		setSeeDiff(false);
		onCloseThePanel();
		dispatch(campaignActions.handleChangeEmptyState(true));
		handleChangeCampaignOriginalBeforSave({});
		handleIsSaveCp(true);
		campaignService.getOneCampaignById(idOfCampaign, (res) => {
			dispatch(campaignActions.handleChangeEmptyState(false));
			setListOfMessage([]);
			socket && socket.emit('joinRoom', getCurrentuser(user, idOfCampaign));
			setIsRealoading(false);
			handleChangeCampaignOriginalBeforSave({ ...res.data.slideModels[0] });
			setdatasSlidesOfCampaignSelected(res.data.slideModels);
			setSlidTemplatesOfSlideModelSelected(res.data.slideModels[0].slideTemplates[0]);
			setDisplayIdentifier(res.data.slideModels[0].slideTemplates[0].displayIdentifier);
			if (res.data.slideModels[0].slideTemplates.length > 1) {
				let arrayOfSlideIdentifier = transformArrayOfSlideIdentifier(res.data.slideModels[0].slideTemplates);
				setListofDisplayIdentifier(arrayOfSlideIdentifier);
			} else {
				setListofDisplayIdentifier();
			}
			if (res.data.slideModels.length > 1) {
				let arrayOfSlide = res.data.slideModels.map(elem => ({
					value: elem.slideId, label: elem.slideId
				}));
				setListOfSlide(arrayOfSlide);
			} else {
				setListOfSlide();
			}
			setTemplateFormat(res.data.slideModels[0].slideTemplates[0][screenEnvironment].contentTemplateType);
			setSlideName(res.data.slideModels[0].slideName);
			setSlideId(res.data.slideModels[0].slideId);
			let reconstructHref = window.location.href.split(match.params.env);
			window.history.pushState('object or string', 'Title', `${reconstructHref[0]}${match.params.env}/${idOfCampaign}`);
		}, (error) => {
			setIsRealoading(false);
			console.log('error', error);
			createAlerte('error', 'an error occurred !', { removeAllBeforeShow: false, });
		});
	};
	const handleSelectCampaign = async (cpId) => {
		if (isAuthenticated && cpId) {
			setIdOfCampaignSelected(cpId);
			getTheInformationsForTheSelectedCampaign(cpId);
		}
	};

	useEffect(() => {
		if (dataHeaderOfCampaignSelected) {
			statusOperation();
		}
	}, [dataHeaderOfCampaignSelected]);

	useEffect(() => {
		if (idOfCampaignSelected) {
			const dataOfCampaignSelected = dataCampaigns.find(campaign => campaign.id === idOfCampaignSelected);
			setDataHeaderOfCampaignSelected(dataOfCampaignSelected);
			dispatch(campaignActions.refreshCampaignSelected(dataOfCampaignSelected));
		}
		return () => {
		};
	}, [idOfCampaignSelected, dataCampaigns]);
	const seeXSLT = () => {
		setToggleState(1);
	};
	const seeCSS = () => {
		setToggleState(2);
	};
	const seeJS = () => {
		setToggleState(3);
	};
	const seeDisplayCondition = () => {
		setToggleState(4);
	};
	const handleEditorChangeTemplateDesktop = (prevValue, value) => {
		let associateTemplate = value;
		const transformDataSlideTemplate = () => {
			changeSlideCampaignHook(
				associateTemplate,
				datasSlidesOfCampaignSelected,
				slideId,
				displayIdentifier,
				setdatasSlidesOfCampaignSelected,
				screenEnvironment,
				'contentTemplate',
				originalCampaign,
				handleIsSaveCp
			);
		};
		if (associateTemplate !== undefined && datasSlidesOfCampaignSelected && slideId) {
			transformDataSlideTemplate();
		}
	};
	const handleEditorChangeCssDesktop = (prevValue, value) => {
		let associateTemplate = value;
		const transformDataSlideCss = () => {
			changeSlideCampaignHook(
				associateTemplate,
				datasSlidesOfCampaignSelected,
				slideId,
				displayIdentifier,
				setdatasSlidesOfCampaignSelected,
				screenEnvironment,
				'css',
				originalCampaign,
				handleIsSaveCp
			);
		};
		if (associateTemplate !== undefined && datasSlidesOfCampaignSelected && slideId) {
			transformDataSlideCss();
		}
	};
	const handleEditorChangeJsDesktop = (prevValue, value) => {
		let associateTemplate = value;
		const transformDataSlideJs = () => {
			changeSlideCampaignHook(
				associateTemplate,
				datasSlidesOfCampaignSelected,
				slideId,
				displayIdentifier,
				setdatasSlidesOfCampaignSelected,
				screenEnvironment,
				'javascript',
				originalCampaign,
				handleIsSaveCp
			);
		};
		if (associateTemplate !== undefined && datasSlidesOfCampaignSelected && slideId) {
			transformDataSlideJs();
		}
	};
	const handleEditorChangeDisplayConditionDesktop = (prevValue, value) => {
		let associateTemplate = value;
		const transformDataDisplayCondition = () => {
			changeSlideCampaignHook(
				associateTemplate,
				datasSlidesOfCampaignSelected,
				slideId,
				displayIdentifier,
				setdatasSlidesOfCampaignSelected,
				screenEnvironment,
				'displayConditionJavascript',
				originalCampaign,
				handleIsSaveCp
			);
		};
		if (associateTemplate !== undefined && datasSlidesOfCampaignSelected && slideId) {
			transformDataDisplayCondition();
		}
	};
	const handleEditorChangeCssMobile = (prevValue, value) => {
		let associateTemplate = value;
		const transformDataSlideCss = () => {
			changeSlideCampaignHook(
				associateTemplate,
				datasSlidesOfCampaignSelected,
				slideId,
				displayIdentifier,
				setdatasSlidesOfCampaignSelected,
				screenEnvironment,
				'css',
				originalCampaign,
				handleIsSaveCp
			);
		};
		if (associateTemplate !== undefined && datasSlidesOfCampaignSelected && slideId) {
			transformDataSlideCss();
		}
	};

	const handleEditorChangeTemplateMobile = (prevValue, val) => {
		let associateTemplate = val;
		const transformDataSlideTemplate = () => {
			changeSlideCampaignHook(
				associateTemplate,
				datasSlidesOfCampaignSelected,
				slideId,
				displayIdentifier,
				setdatasSlidesOfCampaignSelected,
				screenEnvironment,
				'contentTemplate',
				originalCampaign,
				handleIsSaveCp
			);
		};
		if (associateTemplate !== undefined && datasSlidesOfCampaignSelected && slideId) {
			transformDataSlideTemplate();
		}
	};

	const handleEditorChangeJsMobile = (prevValue, value) => {
		let associateTemplate = value;
		const transformDataSlideJs = () => {
			changeSlideCampaignHook(
				associateTemplate,
				datasSlidesOfCampaignSelected,
				slideId,
				displayIdentifier,
				setdatasSlidesOfCampaignSelected,
				screenEnvironment,
				'javascript',
				originalCampaign,
				handleIsSaveCp
			);
		};
		if (associateTemplate !== undefined && datasSlidesOfCampaignSelected && slideId) {
			transformDataSlideJs();
		}
	};
	const handleEditorChangeDisplayConditionMobile = (prevValue, value) => {
		let associateTemplate = value;
		const transformDataSlideDisplayCondition = () => {
			changeSlideCampaignHook(
				associateTemplate,
				datasSlidesOfCampaignSelected,
				slideId,
				displayIdentifier,
				setdatasSlidesOfCampaignSelected,
				screenEnvironment,
				'displayConditionJavascript',
				originalCampaign,
				handleIsSaveCp
			);
		};
		if (associateTemplate !== undefined && datasSlidesOfCampaignSelected && slideId) {
			transformDataSlideDisplayCondition();
		}
	};
	const today = moment();

	function toggleScreenEnvironnement() {
		setScreenEnvironment(screenEnvironment === 'desktop' ? 'mobile' : 'desktop');
		setTemplateFormat(slidTemplatesOfSlideModelSelected[screenEnvironment === 'desktop' ? 'mobile' : 'desktop'].contentTemplateType);
	}
	const handleSelectVersion = (e) => {
		let dataSlideComplete = [...datasSlidesOfCampaignSelected];
		dataSlideComplete = dataSlideComplete.find(slide => slide.slideId === slideId).slideTemplates.find(el => el.displayIdentifier === e);
		setDisplayIdentifier(dataSlideComplete.displayIdentifier);
		setSlidTemplatesOfSlideModelSelected(dataSlideComplete);
		setTemplateFormat(dataSlideComplete[screenEnvironment].contentTemplateType);
	};
	const handleSelectSlide = (e) => {
		let dataSlideComplete = [...datasSlidesOfCampaignSelected];
		dataSlideComplete = dataSlideComplete.find(slide => slide.slideId === e.target.value);
		if (dataSlideComplete.slideTemplates.length > 1) {
			let arrayOfSlideIdentifier = transformArrayOfSlideIdentifier(dataSlideComplete.slideTemplates);
			setListofDisplayIdentifier(arrayOfSlideIdentifier);
			setDisplayIdentifier(dataSlideComplete.slideTemplates[0].displayIdentifier);
			setSlidTemplatesOfSlideModelSelected(dataSlideComplete.slideTemplates[0]);
			setSlideId(e.target.value);
			setTemplateFormat(dataSlideComplete.slideTemplates[0][screenEnvironment].contentTemplateType);
		} else {
			setListofDisplayIdentifier();
			setDisplayIdentifier(dataSlideComplete.slideTemplates[0].displayIdentifier);
			setSlidTemplatesOfSlideModelSelected(dataSlideComplete.slideTemplates[0]);
			setTemplateFormat(dataSlideComplete.slideTemplates[0][screenEnvironment].contentTemplateType);
			setSlideId(e.target.value);
		}
	};
	const handleChangeFormatTemplateWithDropDown = useCallback((value) => {
		let dataSlideComplete = [...datasSlidesOfCampaignSelected];
		let dataSlideToChange = dataSlideComplete.find(slide => slide.slideId === slideId);
		if (dataSlideToChange) {
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier)[screenEnvironment].contentTemplateType = parseInt(value);
			dataSlideComplete = dataSlideComplete.filter(el => el.slideId !== slideId);
			setTemplateFormat(parseInt(value));
			setdatasSlidesOfCampaignSelected([...dataSlideComplete, dataSlideToChange]);
		}

	}, [datasSlidesOfCampaignSelected, screenEnvironment, slideId, displayIdentifier]);
	const validateCampaignSlides = async (natureOfRegistration) => {
		const arrayOfSlidesVariations = [];
		for (let i = 0; i < datasSlidesOfCampaignSelected.length; i++) {
			for (let y = 0; y < datasSlidesOfCampaignSelected[i].slideTemplates.length; y++) {
				arrayOfSlidesVariations.push(datasSlidesOfCampaignSelected[i].slideTemplates[y]);
			}
		}
		setListErrors([]);
		setHasBloquantError(false);
		campaignService.updateValidateCampaign(arrayOfSlidesVariations,
			(res) => {
				const validArray = res.data;
				const errorsArray = [];
				for (let i = 0; i < validArray.length; i++) {
					if (validArray[i].mobile.length !== 0) {
						setModalErrorsIsOppen(true);
						// eslint-disable-next-line no-loop-func
						validArray[i].mobile.forEach(error => {
							if (error.isWarning === false) {
								setHasBloquantError(true);
							}
							errorsArray.push({ device: 'mobile', identifier: validArray[i].displayIdentifier, isWarning: error.isWarning, message: ListErrorsValidation.find(elem => elem.key === error.errorType).message });
						});
					}
					if (validArray[i].desktop.length !== 0) {
						setModalErrorsIsOppen(true);
						// eslint-disable-next-line no-loop-func
						validArray[i].desktop.forEach(error => {
							if (error.isWarning === false) {
								setHasBloquantError(true);
							}
							errorsArray.push({ device: 'desktop', identifier: validArray[i].displayIdentifier, isWarning: error.isWarning, message: ListErrorsValidation.find(elem => elem.key === error.errorType).message });
						});
					}
				}
				if (errorsArray.length !== 0) {
					setListErrors(errorsArray);
				} else {
					if (natureOfRegistration === 'simple') {
						registerTheChanges(idOfCampaignSelected);
					} else {
						registerTheChangesWithCopy(idOfCampaignSelected);
					}
				}
			}, (error) => {
				setIsRealoading(false);
				createAlerte('error', 'an error occurred !', { removeAllBeforeShow: false, });
			});
	};
	const registerTheChanges = async (idOfCampaign) => {
		setModalIsOppen(false);
		setIsRealoading(true);
		const arrayOfSlidesVariations = [];
		for (let i = 0; i < datasSlidesOfCampaignSelected.length; i++) {
			for (let y = 0; y < datasSlidesOfCampaignSelected[i].slideTemplates.length; y++) {
				arrayOfSlidesVariations.push(datasSlidesOfCampaignSelected[i].slideTemplates[y]);
			}
		}
		let slideModels = [...datasSlidesOfCampaignSelected];
		campaignService.updateCampaign(
			idOfCampaign,
			{ slideModels },
			() => {
				socket &&
                    socket.emit(
                    	'register-campaign',
                    	`${getCurrentuser(user, idOfCampaign).displayName
                    	} has just updated the campaign.
            By saving now, you will erase this last update. We advise you to leave this campaign without saving, and then start over`
                    );
				createAlerte('success', 'Successfully registered changes', {
					removeAllBeforeShow: false,
				});
				setTimeout(() => {
					getTheInformationsForTheSelectedCampaignAfterReload();
				}, 2200);
			},
			(err) => {
				setIsRealoading(false);
				createAlerte(
					'error',
					'an error occurred during the backup',
					{ removeAllBeforeShow: false }
				);
			}
		);

	};
	const registerTheChangesWithCopy = async (idOfCampaign) => {
		setModalIsOppen(false);
		setIsRealoading(true);
		const slideModels = await handleChangesWithCopy(datasSlidesOfCampaignSelected, slideId, screenEnvironment, displayIdentifier);
		slideModels && campaignService.updateCampaign(idOfCampaign, { slideModels }, () => {
			socket && socket.emit('register-campaign', `${getCurrentuser(user).displayName} has just updated the campaign.
            By saving now, you will erase this last update. We advise you to leave this campaign without saving, and then start over`);
			createAlerte('success', 'Changes with copy successfully recorded', { removeAllBeforeShow: false, });
			setTimeout(() => {
				getTheInformationsForTheSelectedCampaignAfterReload();
			}, 2200);
		}, () => {
			setIsRealoading(false);
			StatusAlertService.showError('an error occurred');
		}
		);
	};
	useEffect(() => {
		LinkCampaignToBackOffice(match, idOfCampaignSelected, setLinkToBo);
		let objectToLoadOnDiffEditor = [
			{
				id: 'xml',
				original: '',
				preview: ''
			},
			{
				id: 'css',
				original: '',
				preview: ''
			},
			{
				id: 'javascript',
				original: '',
				preview: ''
			},
			{
				id: 'displayConditionJavascript',
				original: '',
				preview: ''
			}
		];
		if (slidTemplatesOfSlideModelSelected && datasSlidesOfCampaignSelected && slideId && displayIdentifier !== undefined) {
			let dataSlideComplete = [...datasSlidesOfCampaignSelected];
			let dataSlideToChange = dataSlideComplete.find(slide => slide.slideId === slideId);
			if (dataSlideToChange) {
				const template = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier);
				objectToLoadOnDiffEditor.find(el => el.id === 'xml').original = template.desktop.contentTemplate;
				objectToLoadOnDiffEditor.find(el => el.id === 'xml').preview = template.mobile.contentTemplate;
				objectToLoadOnDiffEditor.find(el => el.id === 'css').original = template.desktop.css;
				objectToLoadOnDiffEditor.find(el => el.id === 'css').preview = template.mobile.css;
				objectToLoadOnDiffEditor.find(el => el.id === 'javascript').original = template.desktop.javascript;
				objectToLoadOnDiffEditor.find(el => el.id === 'javascript').preview = template.mobile.javascript;
				objectToLoadOnDiffEditor.find(el => el.id === 'displayConditionJavascript').original = template.desktop.displayConditionJavascript;
				objectToLoadOnDiffEditor.find(el => el.id === 'displayConditionJavascript').preview = template.mobile.displayConditionJavascript;
			}
			setValuesEditor(objectToLoadOnDiffEditor);
			for (let i = 0; i < objectToLoadOnDiffEditor.length; i++) {
				if (objectToLoadOnDiffEditor[i].original !== objectToLoadOnDiffEditor[i].preview) {
					setIsDifferent(true);
					setIsResponsive(false);
					return;
				}
			}
			setSeeDiff(false);
			setIsDifferent(false);
			setIsResponsive(true);

		}
	}, [slidTemplatesOfSlideModelSelected, slideId]);

	let numberOdErrors = listErrors && listErrors.length;

	const getTheInformationsForTheSelectedCampaignAfterReload = () => {
		resetTemplates();
		setIsRealoading(true);
		setreloadModalIsOpen(false);
		dispatch(campaignActions.handleChangeEmptyState(false));
		handleChangeCampaignOriginalBeforSave({});
		handleIsSaveCp(true);
		campaignService.getOneCampaignById(idOfCampaignSelected, (res) => {
			setdatasSlidesOfCampaignSelected(res.data.slideModels);
			handleChangeCampaignOriginalBeforSave({ ...res.data.slideModels[0] });
			const slideToChange = res.data.slideModels.find(slide => slide.slideId === slideId);
			if (slideToChange.slideTemplates.length > 1) {
				let arrayOfSlideIdentifier = transformArrayOfSlideIdentifier(slideToChange.slideTemplates);
				setListofDisplayIdentifier(arrayOfSlideIdentifier);
				const slideTemplateSelected = slideToChange.slideTemplates.find(el => el.displayIdentifier === displayIdentifier);
				setSlidTemplatesOfSlideModelSelected(slideTemplateSelected);
				setTemplateFormat(slideTemplateSelected[screenEnvironment].contentTemplateType);
			} else {
				const slideTemplateSelected = slideToChange.slideTemplates.find(el => el.displayIdentifier === displayIdentifier);
				setSlidTemplatesOfSlideModelSelected(slideTemplateSelected);
				setTemplateFormat(slideTemplateSelected[screenEnvironment].contentTemplateType);
			}
			setIsRealoading(false);
			createAlerte('success', 'Data reload successfully completed', { removeAllBeforeShow: false, });

		}, (err) => {
			setIsRealoading(false);
			createAlerte('error', 'an error occurred', { removeAllBeforeShow: false, });

		});
	};

	const reloadTheCampaign = () => {
		setIsRealoading(true);
		const data = {
			'status': 'Reload'
		};
		campaignService.updateStatus(data,
			(success) => {
				createAlerte('success', 'Your changes have been applied.', {
					removeAllBeforeShow: false,
				});
				setIsRealoading(false);
			},
			(err) => {
				createAlerte('error', 'An error occured while applying changes.', {
					removeAllBeforeShow: false,
				});
				setIsRealoading(false);
			}
		);

	};
	const statusOfApplyChanges = () => {
		if (statusOp === 'Ongoing') {
			if (isSave) {
				return 'Cannot apply changes on an ongoing campaign.';
			} else {
				return 'Save your changes before applying.';
			}
		}
		if (statusOp !== 'Ongoing') {
			if (isSave) {
				return 'Apply changes';

			} else {
				return 'Save your changes before applying.';
			}
		}
	};
	const disableBtnApplyChanges = () => {
		if (statusOp === 'Ongoing') {
			return false;
		}
		if (statusOp !== 'Ongoing') {
			if (isSave) {
				return false;
			} else {
				return true;
			}
		}
	};

	return (
		<div className="page_body flex flex_v">
			{
				reloadModalIsOpen &&
                <PopinConfirm
                	title="Are you sure you want to cancel? The campaign will be reloaded and your changes will be lost."
                	cancel={() => setreloadModalIsOpen(false)}
                >
                	<Btn
                		onClickFunction={(e) => getTheInformationsForTheSelectedCampaignAfterReload()}
                		message="Yes"
                		color="primary"
                	/>
                </PopinConfirm>
			}
			{modalIpsIsOpen && <EditCampaignTestMode
				campaignService={campaignService}
				reloadCampaignList={reloadCampaignList}
			/>
			}
			{modalErrorsIsOppen && <ModalErrors
				modalErrorsIsOppen={modalErrorsIsOppen}
				numberOdErrors={numberOdErrors}
				setModalErrorsIsOppen={(e) => setModalErrorsIsOppen(e)}
				setModalIsOppen={(e) => setModalIsOppen(e)}
				listErrors={listErrors}
				hasBloquantError={hasBloquantError}
				isDifferent={isDifferent}
				idOfCampaignSelected={idOfCampaignSelected}
				registerTheChanges={(e) => registerTheChanges(e)}
			/>}
			{modalIsOppen && !isDifferent &&
                <Modal
                	isOpen={modalIsOppen}
                	width="800"
                	minHeight="200"
                	onClose={() => { setModalIsOppen(false); }}
                >
                	<div className="center-div-action">
                		<h2>Do you want to copy values from {screenEnvironment} to {screenEnvironment === 'desktop' ? 'mobile' : 'desktop'}</h2>
                	</div>
                	<div className="modal_section_bottom center">
                		<Btn
                			onClickFunction={(e) => registerTheChanges(idOfCampaignSelected)}
                			message="Non"
                			color="alert"
                		/>
                		<Btn
                			onClickFunction={(e) => { registerTheChangesWithCopy(idOfCampaignSelected); }}
                			message="Oui"
                			color="primary"
                		/>
                	</div>
                </Modal>
			}
			<PanelCampaign
				dataCampaigns={dataCampaigns}
				campaignSelected={dataHeaderOfCampaignSelected}
				handleSelectCampaign={handleSelectCampaign}
				env={match.params.env}
			/>
			<div className='flex_item_fix flex flex_justify_between nav'>
				<Nav
					env={match.params.env}
					campaignSelected={dataHeaderOfCampaignSelected}
					listofDisplayIdentifier={listofDisplayIdentifier}
					handleSelectVersion={(e) => handleSelectVersion(e)}
					displayIdentifier={displayIdentifier}
				/>
				{datasSlidesOfCampaignSelected && datasSlidesOfCampaignSelected.length > 1 && slideId && listOfSlides &&
                    <SelectCustom
                    	label="selectionner une slide"
                    	optionsList={listOfSlides}
                    	onChangeFunction={(e) => handleSelectSlide(e)}
                    	value={listOfSlides.find(el => el.value === slideId) ? listOfSlides.find(el => el.value === slideId).value : ''}
                    />
				}
				{listOfUsers &&
                    listOfUsers.filter(u => u.idBeyable !== user.sub).map(user =>
                    	<Avatar
                    		key={user.id}
                    		onClickFunction={() => getUsersRoomInformation()}
                    		user={{ firstName: user.displayName, email: user.email, id: user.idBeyable }}
                    	/>
                    )}
				{
					dataHeaderOfCampaignSelected &&
                    <div className='flex flex_wrap nav-controle'>
                    	<Status
                    		campaignService={campaignService}
                    		status={statusOp}
                    		onClickFunction={() => null}
                    		messageToolTip="Campaign status"
                    		reloadCampaignList={() => reloadCampaignList()}
                    	/>

                    	<>
                    		<Dropdown
                    			isOpen={abTestIsOppen}
                    			setIsOpen={(e) => setabTestIsOppen(true)}
                    			onHide={(e) => setabTestIsOppen(false)}
                    			button={
                    				<Btn
                    					isReactIcon={true}
                    					reactIcon={() => <FaExternalLinkSquareAlt />}
                    					style="outline"
                    					color="white"
                    					message={'Preview'}
                    					messageToolTip="Preview the campaign"
                    					onClickFunction={() => setabTestIsOppen(true)}
                    				/>
                    			}>
                    			<ul className="listbox">
                    				{dataHeaderOfCampaignSelected && dataHeaderOfCampaignSelected.debugLinks.map((x, i) =>
                    					<li key={i}>
                    						<a className={'listbox_item'}
                    							onClick={(e) => {
                    								window.open(x.urlDebug, '_blank');
                    								setabTestIsOppen(false);
                    							}}>
                    							{`Preview ${x.variation !== '' ? x.variation : 'Original'}`}
                    						</a>
                    					</li>
                    				)}
                    			</ul>
                    		</Dropdown>
                    	</>

                    	{!isReloading && datasSlidesOfCampaignSelected &&
                            <Btn
                            	onClickFunction={
                            		statusOp !== 'Ongoing' && !isSave ?
                            			isResponsive ? (e) => validateCampaignSlides('copy') : (e) => validateCampaignSlides('simple') : () => { }}
                            	message={'Save'}
                            	disabledBtn={statusOp === 'Ongoing' ?
                            		true
                            		:
                            		isSave ? true : false
                            	}
                            	messageToolTip={statusOp === 'Ongoing' ? 'Cannot save an ongoing campaign.' : 'Save'}
                            	isReactIcon={true}
                            	reactIcon={() => <FaSave />}
                            />}
                    	{!isReloading && datasSlidesOfCampaignSelected &&
                            <Btn
                            	message={'Apply changes'}
                            	messageToolTip={statusOfApplyChanges()}
                            	onClickFunction={statusOp !== 'Ongoing' && isSave ? () => reloadTheCampaign()
                            		: () => { }
                            	}
                            	disabledBtn={disableBtnApplyChanges()}
                            	title="Apply changes"
                            	isReactIcon={true}
                            	reactIcon={() => <FiRefreshCw />}
                            />
                    	}

                    </div>
				}
			</div>

			<Portal>
				<div className="notifications_list" >
					{listOfMessage && listOfMessage.map((msg, i) =>
						<Notification
							data={{ ...msg, user: listOfUsers.find(u => u.id === msg.idSocketUser) }}
							type={getTypeMessage(msg.verb)}
							message={msg.text}
							onClickFunction={(e) => updateListOfMessages(msg)}
							key={i}>

						</Notification>)}
				</div>
			</Portal>
			<div className='flex_item_full flex flex_justify_start flex_align_stretch'>
				<Sidebar
					deviceSelected={screenEnvironment}
					selectDevice={(e) => toggleScreenEnvironnement(e)}
					isDifferent={isDifferent}
					onClickDiff={() => setSeeDiff(!seeDiff)}
					seeDiff={seeDiff}
					reloadData={() => setreloadModalIsOpen(true)}
					openUploadPicture={() => dispatch(bespokeActions.toggleUploadPicture(true))}
					handleResponsive={(e) => setIsResponsive(e.target.checked)}
					isResponsiveValue={isResponsive}
					dataCampaign={dataHeaderOfCampaignSelected}
					setDataCampaign={setDataHeaderOfCampaignSelected}
					setDataCampaigns={setDataCampaigns}
					linkToBo={linkToBo}
					datasSlidesOfCampaignSelected={datasSlidesOfCampaignSelected}
					env={match.params.env}
					accountId={match.params.accountId}
					isOpen={isOpen}
					setisOpen={setisOpen}
				/>
				{seeUploadPicture &&
                    <div className='flex_item_full flex flex_v'>
                    	<ImagesAccountContainer
                    		accountId={match.params.accountId}
                    		env={match.params.env} />
                    </div>
				}
				{!isEmptyState &&
                    <div className='flex_item_full flex flex_v'>
                    	<div className="contentOfLanguage flex_item_fix">
                    		<div className="flex flex_justify_start flex_align_end">
                    			<div className={`onglets ${toggleState === 1 ? 'activeOnglet' : ''} ongletHtml`} onClick={seeXSLT}>
                    				<div className="flex">
                    					<p className='mr_3'>Template</p>
                    					<SelectTemplate
                    						onSelectTemplateType={(val) => handleChangeFormatTemplateWithDropDown(val)}
                    						templateType={[{ value: 1, label: 'XSLT' },
                    							{ value: 2, label: 'LIQUID' }]}
                    						templateTypeSelected={templateType.find(el => el.value === templateFormat) && templateType.find(el => el.value === templateFormat).label}
                    					/>
                    				</div>
                    			</div>
                    			<div className={`onglets ${toggleState === 2 ? 'activeOnglet' : ''} ongletCss`} onClick={seeCSS}>  CSS</div>
                    			<div className={`onglets ${toggleState === 3 ? 'activeOnglet' : ''} ongletJs`} onClick={seeJS}>  JS</div>
                    			<div className={`onglets ${toggleState === 4 ? 'activeOnglet' : ''} ongletJs`} onClick={seeDisplayCondition}>  Display condition (JS)</div>
                    		</div>
                    	</div >

                    	{!seeDiff &&
                            <IdeContainer
                            	screenEnvironment={screenEnvironment}
                            	toggleState={toggleState}
                            	slideName={slideName}
                            	handleEditorChangeTemplateDesktop={handleEditorChangeTemplateDesktop}
                            	handleEditorChangeCssDesktop={handleEditorChangeCssDesktop}
                            	handleEditorChangeCssMobile={handleEditorChangeCssMobile}
                            	handleEditorChangeTemplateMobile={handleEditorChangeTemplateMobile}
                            	handleEditorChangeJsMobile={handleEditorChangeJsMobile}
                            	handleEditorChangeDisplayConditionMobile={handleEditorChangeDisplayConditionMobile}
                            	handleEditorChangeDisplayConditionDesktop={handleEditorChangeDisplayConditionDesktop}
                            	handleEditorChangeJsDesktop={handleEditorChangeJsDesktop}
                            	slidTemplatesOfSlideModelSelected={slidTemplatesOfSlideModelSelected}
                            	theme={theme}
                            	env={match.params.env}
                            	isOpen={isOpen}
                            />
                    	}
                    	{seeDiff &&
                            <MonacoDiffEditorCampaign
                            	theme={theme}
                            	val={valuesEditor}
                            	toggleState={toggleState}
                            	language={arrayOfLanguages.find(el => el.key === toggleState).value}
                            />
                    	}
                    </div>
				}
				{isEmptyState &&
                    <EmptyStateCampaign />
				}
			</div>
			{
				isReloading &&
                <SpinnerOverlay />
			}
		</div>
	);
}
