import React, { useRef, useState } from 'react';
import options from './options';
import { ControlledEditor } from '@monaco-editor/react';
import { FillSpinner as Loader } from 'react-spinners-kit';
import { FaMobileAlt } from 'react-icons/fa';
export default function AccountIdeEditorMobile(props) {

	const [isEditorReady, setIsEditorReady] = useState(false);
	const valueGetter = useRef();
	function handleEditorDidMount(event) {
		setIsEditorReady(true);
		valueGetter.current = event;
	}

	return (
		<>
			<ControlledEditor
				theme={props.theme}
				language={props.language}
				loading={<Loader />}
				value={props.value && props.value}
				onChange={props.changeValue}
				editorDidMount={handleEditorDidMount}
				options={options}
				automaticLayout={true}
			/>
		</>
	);
}
